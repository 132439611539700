#root {
  width: 100vw;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
}

h1 {
  color: #2d3c8a;
  font-size: 2.5rem;
  font-weight: normal;
  font-family: Roboto, sans-serif;
  margin-block-end: 0rem;
  margin-block-start: 0rem;
}

h2 {
  color: #2d3c8a;
  font-family: Roboto, sans-serif;
  font-weight: normal;
}

h4 {
  color: #2d3c8a;
  font-weight: 400;
}

p {
  color: #2d3c8a;
  margin-block-start: 0rem;
  font-size: 1.1rem;
}

ul {
  list-style-type: none;
  padding-inline-start: 0em;
  margin-block-start: 0rem;
}

li {
  margin-bottom: 0.25rem;
}

a {
  color: #e16a1e;
  font-weight: bold;
}

a:hover {
  color: #2d3c8a;
  cursor: pointer;
}

footer a {
  font-weight: 500;
  text-decoration: none;
  color: white;
}

footer a:hover {
  color: #c1c8eb;
  cursor: pointer;
  transform: scale(1.2)
}

.footer-text {
  margin-bottom: 0em;
  color:white;
  font-size: 1rem;
}

.share-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.pl-1rem {
  margin-left: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home-bodytext,
.bodytext {
  max-width: 700px;
}

/* .mdc-text-field .mdc-text-field__input {
  caret-color: #7768a6 !important;
}
.mdc-floating-label {
  color: #7768a6 !important;
}
.mdc-line-ripple {
  background-color: #7768a6 !important;
} */

input[type='text'] {
  border: 1px solid lightgrey;
  margin-top: 0.5rem;
  width: 12rem;
  min-height: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
}

input[type='text']:focus {
  outline-color: #5763a2;
  outline-style: solid;
  outline-width: 1px;
  outline-offset: 1px;
  border: none;
}

label {
  margin-left: 0.5rem;
}

@media (min-width: 600px) {
  input[type='text'] {
    width: 35rem;
  }
}
